import { useState } from "react";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* PACKAGES*/
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";

/* ANIMATION DATA */
import { animation } from "config";

/* ICONS */
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

/* SAMPLE DATA */
import { footerNav } from "data/layout";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";
import { GCP_BUCKET_URL } from "config";
import { FaTwitter } from "react-icons/fa6";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

const Footer = () => {
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.footer variants={animation} className="bg-footer">
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      <div className="container mx-auto">
        {/* NEWSLETTER */}
        <div className="py-5 xl:py-10 lg:py-10">
          <div className="block xl:flex lg:flex items-center justify-between">
            <div className="w-full xl:w-2/5 lg:w-2/5">
              <h2 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-white">
                Sign up to stay updated!
              </h2>
              <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-white text-opacity-70 mt-2">
                Get the latest news, tips, and exclusive offers delivered
                straight to your inbox. Sign up now for our newsletter.
              </h5>
            </div>
            <div className="w-full xl:w-1/2 lg:w-1/2 mt-5 xl:mt-0 lg:mt-0">
              <form
                onSubmit={formHandling.handleSubmit}
                className="block xl:flex lg:flex items-center justify-end gap-3"
              >
                <div className="w-full xl:w-3/5 lg:w-3/5 ">
                  <input
                    type="email"
                    name="email"
                    placeholder="Type your email address"
                    value={formHandling.values.email}
                    onChange={formHandling.handleChange}
                    className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                      formHandling.errors.email && formHandling.touched.email
                        ? "border-red-500"
                        : "border-greyBorder"
                    } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                  />
                </div>
                <Button
                  disabled={formLoading}
                  type={formLoading ? "button" : "submit"}
                  className="mt-3 xl:mt-0 lg:mt-0 w-full xl:w-fit lg:w-fit shadow-none hover:shadow-none bg-brandColor text-sm font-proxima-semibold text-white normal-case"
                >
                  {formLoading ? "Please wait.." : "Subscribe"}
                </Button>
              </form>
            </div>
          </div>
        </div>

        {/* TOP FOOTER */}
        <div className="border-t border-white border-opacity-20 py-5 xl:py-10 lg:py-10">
          <div className="block xl:flex lg:flex md:flex items-start justify-between">
            {/* ABOUT COMPANY */}
            <div className="w-full xl:w-2/5 lg:w-2/5">
              <h2 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-white">
                Fostering excellence in workforce performance and development;
                we’re your trusted partner for success.
              </h2>
              <div className="mt-5 xl:mt-10 lg:mt-10">
                <div className="flex items-center gap-2">
                  <a
                    href="https://www.facebook.com/alignedrewards.offical/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="shadow-none hover:shadow-none rounded-full p-3 bg-white bg-opacity-5 hover:bg-opacity-20 transition-all duration-300 ease-in-out">
                      <FaFacebook className="w-5 h-5 text-white text-opacity-70" />
                    </Button>
                  </a>
                  <a
                    href="https://www.instagram.com/alignedrewards.official/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="shadow-none hover:shadow-none rounded-full p-3 bg-white bg-opacity-5 hover:bg-opacity-20 transition-all duration-300 ease-in-out">
                      <FaInstagram className="w-5 h-5 text-white text-opacity-70" />
                    </Button>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/alignedrewardsoffical/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="shadow-none hover:shadow-none rounded-full p-3 bg-white bg-opacity-5 hover:bg-opacity-20 transition-all duration-300 ease-in-out">
                      <FaLinkedin className="w-5 h-5 text-white text-opacity-70" />
                    </Button>
                  </a>
                  <a
                    href="https://twitter.com/AlignedRewards"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button className="shadow-none hover:shadow-none rounded-full p-3 bg-white bg-opacity-5 hover:bg-opacity-20 transition-all duration-300 ease-in-out">
                      <FaTwitter className="w-5 h-5 text-white text-opacity-70" />
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            {/* NAVIGATION LINKS */}
            <div className="w-full xl:w-1/2 lg:w-1/2 mt-5 xl:mt-0 lg:mt-0 ">
              <div className="flex items-center justify-start xl:justify-end lg:justify-end gap-10 xl:gap-28 lg:gap-28 flex-wrap">
                {footerNav.map((item) => (
                  <div className="p-0" id={item.id}>
                    <h5 className="text-lg font-proxima-semibold text-white">
                      {item.title}
                    </h5>
                    {item.list.map((data) => (
                      <div className="mt-2" key={data.id}>
                        <Link
                          to={data.route}
                          className="block text-base font-proxima-regular text-white text-opacity-70 hover:text-brandColor transition-all duration-300 ease-in-out mt-5"
                        >
                          {data.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* POLICY PAGES SECTION */}
        <div className="border-t border-white border-opacity-20 py-4 hidden">
          <div className="block xl:flex lg:flex items-center justify-center xl:justify-between lg:justify-between">
            <div className="bg-white rounded-md py-2 px-3 w-fit mx-auto xl:mx-0 lg:mx-0">
              <img
                src={`${GCP_BUCKET_URL}/aligned-rewards-logo.png`}
                className="w-auto h-12 mx-auto xl:mx-0 lg:mx-0"
                alt="Aligned Rewards - Employee Engagement Software"
              />
            </div>
          </div>
        </div>

        {/* COPYRIGHT FOOTER */}
        <div className="border-t border-white border-opacity-20 py-5">
          <div className="block xl:flex lg:flex items-center justify-center xl:justify-between lg:justify-between">
            <p className="text-sm font-proxima-regular text-white text-opacity-70 text-center">
              Copyright 2025 © Aligned Rewards. All right reserved. Powered by{" "}
              <a
                href="https://www.roundtechsquare.com"
                target="_blank"
                rel="noreferrer"
                className="text-brandColor"
              >
                RoundTechSquare
              </a>
            </p>
            <Link
              to="/privacy-policy"
              className="text-sm font-proxima-regular text-white text-opacity-70 hover:text-brandColor transition-all duration-300 ease-in-out"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
