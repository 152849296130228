const pricingPlans = [
  {
    id: 1,
    name: "Foundation Plan",
    description: "For companies beginning with automation",
    price: "12",
    additionalPrice: "1.10",
    features: [
      {
        id: 1,
        title: "Core HR",
        features: [
          {
            id: 1,
            title: "Organization Structure Management",
          },
          {
            id: 2,
            title: "Employee Onboarding (Basic)",
          },
          {
            id: 3,
            title: "Dynamic Employee Profiles",
          },
          {
            id: 4,
            title: "Standard Access Roles",
          },
          {
            id: 5,
            title: "Employee Exit Management",
          },
          {
            id: 6,
            title: "Notification Engine (Basic)",
          },
          {
            id: 7,
            title: "Reports (Basic)",
          },
        ],
      },
      {
        id: 2,
        title: "Time & Attendance",
        features: [
          {
            id: 1,
            title: "Leave Management System",
          },
          {
            id: 2,
            title: "Shift Management (Basic)",
          },
          {
            id: 3,
            title: "Holiday Calendar Customization",
          },
        ],
      },
      {
        id: 3,
        title: "Payroll & Expense (Razorpay)",
        features: [
          {
            id: 1,
            title: "Payroll Automation",
          },
          {
            id: 2,
            title: "Statutory Compliance (PF, ESI, TDS)",
          },
          {
            id: 3,
            title: "Integration with Accounting Software",
          },
          {
            id: 4,
            title: "Loans & Salary Advances",
          },
          {
            id: 5,
            title: "Gratuity Management",
          },
          {
            id: 6,
            title: "Perks & Benefits Management",
          },
          {
            id: 7,
            title: "Expense Management (Basic)",
          },
          {
            id: 8,
            title: "Pay Slips & Tax Forms Distribution",
          },
        ],
      },
      {
        id: 4,
        title: "Employee Self-Service",
        features: [
          {
            id: 1,
            title: "Employee Portal",
          },
          {
            id: 2,
            title: "Mobile App Access",
          },
          {
            id: 3,
            title: "Basic Inbox Notifications",
          },
          {
            id: 4,
            title: "Profile Updates",
          },
        ],
      },
      {
        id: 5,
        title: "Employee Engagement",
        features: [
          {
            id: 1,
            title: "Rewards and Leaderboards",
          },
          {
            id: 2,
            title: "Managerial Challenges for Incentives",
          },
          {
            id: 3,
            title: "Employee Surverys (Basic)",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Strength Plan",
    description:
      "For companies scaling with advanced automation and employee engagement",
    price: "15",
    additionalPrice: "1.80",
    features: [
      {
        id: 1,
        title: "Core HR Enhancements",
        features: [
          {
            id: 1,
            title: "Advanced Employee Onboarding (Customisable worklows)",
          },
          {
            id: 2,
            title: "Role-Based Access Controls",
          },
          {
            id: 3,
            title: "Employee Productivity Tracking",
          },
        ],
      },
      {
        id: 2,
        title: "Payroll & Expense Enhancements",
        features: [
          {
            id: 1,
            title: "Customisable Salary Structures",
          },
          {
            id: 2,
            title: "Advanced Expense Policies",
          },
          {
            id: 3,
            title: "Multiple Payroll Cycles",
          },
        ],
      },
      {
        id: 3,
        title: "Performance Management",
        features: [
          {
            id: 1,
            title: "Goal Setting & Tracking (Basic)",
          },
          {
            id: 2,
            title: "Performance Reviews (Basic)",
          },
        ],
      },
      {
        id: 4,
        title: "Employee Self-Service Upgrades",
        features: [
          {
            id: 1,
            title: "Single Sign-On (SSO)",
          },
          {
            id: 2,
            title: "Mobile OTP Login",
          },
          {
            id: 3,
            title: "Notification Center (Advanced)",
          },
        ],
      },
      {
        id: 5,
        title: "Employee Engagement",
        features: [
          {
            id: 1,
            title: "Customizable Rewards According to Company Policies",
          },
        ],
      },
      {
        id: 6,
        title: "Analytics and Reporting",
        features: [
          {
            id: 1,
            title: "Customizable Reports",
          },
          {
            id: 2,
            title: "Detailed Attendance Analytics",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Growth Plan",
    description:
      "For companies aiming to align employees in their growth journey and maximize performance",
    price: "20",
    additionalPrice: "2.40",
    features: [
      {
        id: 1,
        title: "Core HR Premium Features",
        features: [
          {
            id: 1,
            title: "Custom HR Workflows",
          },
          {
            id: 2,
            title: "Advanced Notification Engine (Worklow-Based Alerts)",
          },
          {
            id: 3,
            title: "Talent Acquisition Support (Recruitment Module)",
          },
        ],
      },
      {
        id: 2,
        title: "Advanced Time & Attendance",
        features: [
          {
            id: 1,
            title: "Shift Pattern Forecasting",
          },
          {
            id: 2,
            title: "Scheduling Based on Demand",
          },
        ],
      },
      {
        id: 3,
        title: "Performance Management",
        features: [
          {
            id: 1,
            title: "OKRs (Objectives and Key Results)",
          },
          {
            id: 2,
            title: "360-Degree Feedback",
          },
          {
            id: 3,
            title: "Continuous Feedback Loop",
          },
          {
            id: 4,
            title: "Skill-Based Performance Tracking",
          },
        ],
      },
      {
        id: 4,
        title: "Employee Self-Service Premium",
        features: [
          {
            id: 1,
            title: "Access via Chatbot Integration",
          },
          {
            id: 2,
            title: "Custom Dashboards for Employees",
          },
          {
            id: 3,
            title: "Advanced Notification Center with Reminders",
          },
        ],
      },
      {
        id: 5,
        title: "Employee Engagement & Retention",
        features: [
          {
            id: 1,
            title: "Advanced Employee Surveys with Analytics",
          },
          {
            id: 2,
            title: "Engagement Heatmaps",
          },
          {
            id: 3,
            title: "Rewards and Recognition Programs Integration",
          },
          {
            id: 4,
            title: "Exit Interview Management",
          },
        ],
      },
      {
        id: 6,
        title: "Analytics and Insights",
        features: [
          {
            id: 1,
            title: "Predictive Workforce Analytics",
          },
          {
            id: 2,
            title: "Custom Dashboards for HR and Leadership",
          },
          {
            id: 3,
            title: "Attrition & Retention Analysis",
          },
        ],
      },
      {
        id: 7,
        title: "Learning & Development",
        features: [
          {
            id: 1,
            title: "Training Management System",
          },
          {
            id: 2,
            title: "Skill Gap Assessments",
          },
          {
            id: 3,
            title: "Learning Paths and Certification Tracking",
          },
        ],
      },
      {
        id: 8,
        title: "Support",
        features: [
          {
            id: 1,
            title: "Dedicated Account Manager",
          },
          {
            id: 2,
            title: "Priority Support",
          },
          {
            id: 3,
            title: "Onboarding Support",
          },
        ],
      },
      {
        id: 9,
        title: "Premium Payroll & Expense Features (In Production)",
        features: [
          {
            id: 1,
            title: "Payroll Reconciliation Dashboard",
          },
          {
            id: 2,
            title: "Global Payroll (Multi-Currency Support)",
          },
          {
            id: 3,
            title: "Travel Expenses Reimbursement Automation",
          },
        ],
      },
    ],
  },
];

export default pricingPlans;
