/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Card, CardBody } from "@material-tailwind/react";

/* ANIMATION */
import { animation, containerAnimation } from "config";

/* USER DEFINED COMPONENTS */
import { CallToAction, FrequentlyAskedQuestions } from "components/homepage";

/* ICONS */
import { FaCircleCheck } from "react-icons/fa6";

/* DATA */
import pricingPlans from "data/pricing";

const Pricing = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div
        variants={animation}
        className="bg-pricingBlue shadow-inner pt-5 xl:pt-14 lg:pt-14 rounded-b-3xl overflow-hidden"
      >
        <div className="container mx-auto">
          {/* TEXT AND TESTIMONIALS */}
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 items-start pb-5 xl:pb-20 lg:pb-20">
            <div className="col-span-1">
              <h1 className="text-3xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-tight">
                Select a plan tailored to fit your organization’s needs
              </h1>
              <h2 className="text-lg xl:text-xl lg:text-xl font-proxima-regular text-black text-opacity-80 mt-5 leading-normal">
                Boost employee engagement and streamline 360° performance
                feedback for improved success rates.
              </h2>
              <div className="mt-10 flex items-center gap-5">
                <h5 className="text-base font-proxima-semibold text-black text-opacity-80">
                  Powered by
                </h5>
                <img
                  src="https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/stripe.webp"
                  className="h-8 w-auto object-cover"
                  alt="Stripe"
                />
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-end relative mt-5 xl:mt-0 lg:mt-0">
              <div className="w-full xl:w-3/4 lg:w-3/4 bg-white rounded-2xl p-5 xl:p-10 lg:p-10 shadow-md z-20">
                <h5 className="text-base font-proxima-semibold text-black text-opacity-80 leading-relaxed">
                  You cannot mandate productivity; you must provide the tools to
                  let people become their best.
                </h5>
                <div className="mt-5">
                  <h5 className="text-sm font-proxima-semibold text-black">
                    Steve Jobs
                  </h5>
                  <p className="text-sm font-proxima-regular text-black text-opacity-80">
                    Former CEO of Apple
                  </p>
                </div>
              </div>
              <div className="absolute top-0 left-0 z-0 hidden xl:block lg:block">
                <svg
                  class="blob-testimonial"
                  width="722"
                  height="695"
                  viewBox="0 0 722 695"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transform: "translate(70px, -370.31px)",
                    willChange: "auto",
                    opacity: "1",
                    visibility: "inherit",
                  }}
                >
                  <path
                    d="M401.606 4.46498C329.793 10.8255 258.937 20.3406 189.117 41.9216C112.73 65.5312 29.6997 96.4867 9.05244 184.404C-4.33126 241.425 -0.043148 298.693 11.6032 355.717C28.7117 439.488 51.7424 532.219 100.553 603.478C167.404 701.053 267.345 701.653 374.249 687.73C467.583 675.558 566.026 651.066 639.346 588.892C712.261 527.051 732.701 434.005 716.187 343.499C699.263 250.792 681.464 150.465 627.535 70.8154C573.32 -9.22899 487.063 -3.03786 401.597 4.51246L401.606 4.46498Z"
                    fill="#DCECF5"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PRICING PLANS */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-10 lg:gap-10">
            {pricingPlans.map((plan, index) => (
              <div className="col-span-1" key={plan.id}>
                <div
                  className={`${
                    plan.name === "Strength Plan"
                      ? "bg-brandColor bg-opacity-10 border border-brandColor border-opacity-60"
                      : "bg-white border border-greyBorder"
                  } rounded-2xl px-5 xl:px-10 lg:px-10 py-5 xl:py-8 lg:py-8 shadow-md h-full`}
                >
                  {/* TOP SECTION */}
                  <div
                    className={`border-b ${
                      plan.name === "Strength Plan"
                        ? "border-brandColor border-opacity-40"
                        : "border-greyBorder"
                    } pb-5`}
                  >
                    <h5 className="text-xl font-tight-bold text-black">
                      {plan.name}
                    </h5>
                    <p className="h-10 mt-1 text-base font-proxima-regular text-greyText">
                      {plan.description}
                    </p>
                    <h2 className="text-4xl font-proxima-bold text-black mt-5">
                      ${plan.price}
                      <span className="text-lg font-proxima-semibold ml-1">
                        / month for upto 100 employees
                      </span>
                    </h2>
                    <p className="mt-2 text-base font-proxima-regular text-greyText">
                      +{" "}
                      <span className="text-lg font-proxima-semibold text-black">
                        ${plan.additionalPrice}
                      </span>{" "}
                      per additional employee
                    </p>
                  </div>

                  {/* BOTTOM SECTION */}
                  <div className="py-8 h-auto xl:h-[176vh] lg:h-[176vh] border-b border-greyBorder">
                    {index + 1 > 1 && (
                      <h5 className="text-lg font-proxima-bold text-black">
                        Includes All{" "}
                        {plan.name === "Strength Plan"
                          ? "Foundation"
                          : "Strength"}{" "}
                        Features, Plus:
                      </h5>
                    )}

                    {plan.features.map((data, index) => (
                      <div key={index} className="first:mt-0 mt-6">
                        <h5 className="text-lg font-proxima-bold text-black">
                          {data.title}:
                        </h5>
                        <div className="ml-3 mt-3">
                          {data.features.map((feature) => (
                            <div
                              className="flex items-start gap-3 first:mt-0 mt-3"
                              key={feature.id}
                            >
                              <FaCircleCheck className="mt-1 w-4 h-4 text-green-400 flex-shrink-0" />
                              <p className="text-base font-proxima-regular text-black">
                                {feature.title}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* ADD ONS */}
                  <div className="pt-5">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Additional Add-Ons:
                    </h5>
                    <div className="ml-3 mt-3">
                      <div className="flex items-start gap-3 mt-3">
                        <FaCircleCheck className="mt-1 w-4 h-4 text-green-400 flex-shrink-0" />
                        <p className="text-base font-proxima-regular text-black">
                          Advanced Recruitment and ATS Integration
                        </p>
                      </div>
                      <div className="flex items-start gap-3 mt-3">
                        <FaCircleCheck className="mt-1 w-4 h-4 text-green-400 flex-shrink-0" />
                        <p className="text-base font-proxima-regular text-black">
                          Compliance Tracking for Specific Industries
                        </p>
                      </div>
                      <div className="flex items-start gap-3 mt-3">
                        <FaCircleCheck className="mt-1 w-4 h-4 text-green-400 flex-shrink-0" />
                        <p className="text-base font-proxima-regular text-black">
                          AI-Powered Chatbots for HR Queries
                        </p>
                      </div>
                      <div className="flex items-start gap-3 mt-3">
                        <FaCircleCheck className="mt-1 w-4 h-4 text-green-400 flex-shrink-0" />
                        <p className="text-base font-proxima-regular text-black">
                          Custom Branding for Employee Portals
                        </p>
                      </div>
                      <div className="flex items-start gap-3 mt-3">
                        <FaCircleCheck className="mt-1 w-4 h-4 text-green-400 flex-shrink-0" />
                        <p className="text-base font-proxima-regular text-black">
                          API Access for Integrations
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-10">
            <Card className="bg-white border border-greyBorder rounded-xl shadow-md">
              <CardBody className="px-10 py-8">
                <div className="flex items-center justify-between">
                  <div className="p-0">
                    <h5 className="text-xl font-proxima-semibold text-black">
                      Enterprise
                    </h5>
                    <h5 className="mt-1 text-2xl font-tight-bold text-black">
                      Need more features according to your requirements?
                    </h5>
                    <p className="text-lg font-proxima-regular text-greyText">
                      Customize a pricing plan that scales to your requirements
                    </p>
                  </div>
                  <Button className="shadow-none hover:shadow-none py-[1vh] bg-white hover:bg-black hover:bg-opacity-10 border-2 border-black text-black rounded-md text-base font-proxima-semibold font-normal normal-case mt-5 transition-all duration-300 ease-in-out">
                    Contact us
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* CALL TO ACTION */}
      <CallToAction />
    </motion.section>
  );
};

export default Pricing;
